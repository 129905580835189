document.addEventListener("DOMContentLoaded", () => {
    const alertUseTheApp = document.getElementById("alert-use-the-app");

    if (!alertUseTheApp) {
        return;
    }

    alertUseTheApp.addEventListener("closed.bs.alert", (event) => {
        const hostnameBase = window.location.hostname.replace(/^[^.]*./, "");

        document.cookie =
            "alert_use_the_app=true; max-age=2592000; path=/; domain=" +
            hostnameBase +
            "; secure; samesite=lax";
    });
});
